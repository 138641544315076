import React from 'react'
import { Link, graphql } from 'gatsby';
import { Card, Layout, SEO, Spotlight, Testimonial } from '../../components';

export const query = graphql`
  query {
    upvc: file(absolutePath: { regex: "/upvc.jpg/" }) { childImageSharp { fluid(maxHeight: 400, maxWidth: 900, fit: OUTSIDE) { ...GatsbyImageSharpFluid_withWebp }}},
    glass: file(absolutePath: { regex: "/glass.jpg/" }) { childImageSharp { fluid(maxHeight: 200, maxWidth: 680, fit: OUTSIDE) { ...GatsbyImageSharpFluid_withWebp }}},
    window: file(absolutePath: { regex: "/window.jpg/" }) { childImageSharp { fluid(maxHeight: 200, maxWidth: 680, fit: OUTSIDE) { ...GatsbyImageSharpFluid_withWebp }}},
    wall: file(absolutePath: { regex: "/wall.jpg/" }) { childImageSharp { fluid(maxHeight: 200, maxWidth: 680, fit: OUTSIDE) { ...GatsbyImageSharpFluid_withWebp }}},
    plaster: file(absolutePath: { regex: "/plaster-1.jpg/" }) { childImageSharp { fluid(maxHeight: 200, maxWidth: 680, fit: OUTSIDE) { ...GatsbyImageSharpFluid_withWebp }}},
    kitchen: file(absolutePath: { regex: "/kitchen.jpg/" }) { childImageSharp { fluid(maxHeight: 200, maxWidth: 680, fit: OUTSIDE) { ...GatsbyImageSharpFluid_withWebp }}},
  }
`
const structuredData = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "name": "Services",
  }]
}

const Services = ({data}) => (
  <Layout headerBreakpoint={170}>

    <SEO title="Services" structuredData={structuredData} />

    <Spotlight img={data.upvc}/>

    <section>
      <h1>Services</h1>

      <h2>Glazing, Decorating and so much more...</h2>

      <p>
        Due to our team's vast experience, we offer a wide variety of services. Anything to do with Glass and Glazing, Painting and Decorating, Plastering and Rendering, or even just general maintenance; we know we can help!
      </p>

      <p>
        We can’t <strong>just</strong> ‘help’ – All members of our fully qualified team have years of experience and strive to ensure all work is completed to nothing but the very highest standards.
      </p>
    </section>

    <section variant="grey">
      <div>
        <div style={{overflow: 'hidden', margin: '-20px'}}>
          <Card image={data.window}>
            <h3>Glass, Double Galzing &amp; Glazing Services</h3>
            
            <p></p>

            <Link to="/services/glazing-services" className="button primary">Find Out More</Link>
          </Card>

          <Card image={data.wall}>
            <h3>Painting &amp; Decorating</h3>

            <p>If you're after a new look for your home, office or anything inbetween, we can help. Of course, this can mean more than just a lick of paint!</p>

            <Link to="/services/painting-and-decorating" className="button primary">Find Out More</Link>
          </Card>

          <Card image={data.plaster}>
            <h3>Plastering &amp; Rendering</h3>

            <p>If you've had some building work done, have been unfortunately affected by water damage or just in some general need of plastering skills, then we have the experienced team for you.</p>

            <Link to="/services/plastering-and-rendering" className="button primary">Find Out More</Link>
          </Card>

          <Card image={data.kitchen}>
            <h3>General Maintenance</h3>

            <p>When you're project or requirements don't fall into a set category, we can probably still help. With our skilled and experience team, we can find a solution for you.</p>

            <Link to="/services/general-maintenance" className="button primary">Find Out More</Link>
          </Card>
        </div>
      </div>
    </section>

    <Testimonial />

    <section>
      <h2>But that's not all...</h2>

      <p>...if you're requirements don't seem to match up to our services above, please still do get in touch. Our talented team has been built from hugely varying trades with expreince across the board, and we will endeavour to help you.</p>

      <Link to="/contact" className="button secondary">Let's Talk</Link>
    </section>
  </Layout>
)

export default Services
